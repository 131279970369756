<template>

  <v-container fluid>

    <v-row>

      <v-col>

        <v-card>

          <v-card-title>
            <span class="text-h5">Welcome to Holistic FaaS</span>
          </v-card-title>

          <v-card-subtitle>
            A web-based platform for management of serverless applications in Federated FaaS
          </v-card-subtitle>

          <v-spacer></v-spacer>

          <v-card-text>
            Holistic FaaS is a serverless platform that allows you to deploy and manage your workflows in a simple and
            efficient way.
          </v-card-text>



        </v-card>

      </v-col>


    </v-row>

    <v-row>

      <v-col>

        <v-card>

          <v-card-title>
            <span class="text-h5">Workflows</span>
          </v-card-title>

          <v-card-text>
            Add, manage and deploy Workflows
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="$router.push({ path: '/workflows'})">Explore</v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

<!--      <v-col>-->

<!--        <v-card>-->

<!--          <v-card-title>-->
<!--            <span class="text-h5">Functions</span>-->
<!--          </v-card-title>-->

<!--          <v-card-text>-->
<!--            Add and deploy Functions-->
<!--          </v-card-text>-->

<!--          <v-card-actions>-->
<!--            <v-btn color="primary" text @click="$router.push({ path: '/functions'})">Explore</v-btn>-->
<!--          </v-card-actions>-->

<!--        </v-card>-->

<!--      </v-col>-->


      <v-col>

        <v-card>

          <v-card-title>
            <span class="text-h5">Profile</span>
          </v-card-title>

          <v-card-text>
            Manage your user account
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="$router.push({ path: '/profile'})">Explore</v-btn>
          </v-card-actions>

        </v-card>

      </v-col>


    </v-row>

  </v-container>

</template>

<script>

export default {
  name: "HomeView.vue",
}
</script>

<style scoped>

</style>